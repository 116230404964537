/* base */
@font-face {
    font-family: 'Acme';
    font-style: normal;
    font-weight: 400;
    src: url(/fonts/RrQfboBx-C5_XxrBbg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
$primary-font: 'Acme', serif;
$white: #fff;
$dark: #343d30;
$darker: darken($dark, 20%);
$primary: #F7941D;
$bg-color: #4a5645;
$border-color: #ccc;
a {
    text-decoration: none;
    
    :hover {
        text-decoration: underline;
        text-decoration-color: $primary;
    }
}
