/* Animation - Ken Burns effect */
@-webkit-keyframes move {
    0% {
        -webkit-transform-origin: bottom left;
        -moz-transform-origin: bottom left;
        -ms-transform-origin: bottom left;
        -o-transform-origin: bottom left;
        transform-origin: bottom left;
        transform: scale(1.0);
        -ms-transform: scale(1.0);
        -webkit-transform: scale(1.0);
        -o-transform: scale(1.0);
        -moz-transform: scale(1.0);
    }
    100% {
        transform: scale(1.2);
        -ms-transform: scale(1.2);
        -webkit-transform: scale(1.2);
        -o-transform: scale(1.2);
        -moz-transform: scale(1.2);
    }
}