/* layout */
/* Header */
.header-spacer {
    font-family: $primary-font;
    background-color: $white;
    position: fixed;
    top: 25px;
    right: 0;
    left: 0;
    z-index: 1020;
    padding: 8px 15px 0 15px;
}

/* Social */
.social {
    background-color:$darker;
    color: $white;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    padding: 0 15px 0 15px;
    height: 25px;
}

.social-link {
    padding-right: 20px;
    color: $white;
}
.social-link:hover {
    color: $primary;
}

/* Card */
.card {
    margin: 0 auto;
    float: none;
    margin-bottom: 10px;
}


.card-title {
    font-family: $primary-font;
    color: $darker;
}


.card-title.pricing {
    font-family: $primary-font;
    color: $white;
    padding: 1em;
}
.icon {
    color: $primary;
    padding: 1em !important;
}

.logo {
    position:absolute;
    top: 2em;
    z-index: 1031;
    left: 2em;
}
.list-group-item {
    border: none !important;
}
.page-nav {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1029;
}
.blockquote {
    color: $primary;
}

.page-banner-area {
    position: relative;
    min-height: 450px;
    color: $white;
    background-position: 50% 50%;
    -webkit-background-size: cover;
    background-size: cover;
    -webkit-backface-visibility: hidden;
    margin-bottom: 2em;
}

.page-banner-title {
    position: absolute;
    text-shadow: 0px 0px 8px rgba(0,0,0,0.5),0px 0px 8px rgba(0,0,0,0.5) , 3px 5px 15px rgba(68,68,68,0.76);
    top: 50%;
    width: 100%;
    height: 100%;
    margin-top: -40px;
}
.page-banner-title h2 {
    color: $white;
    font-size: 60px;
}

/* Site Carousel */
#carouselSite .carousel-caption {
    text-shadow: 0px 0px 8px rgba(0,0,0,0.5),0px 0px 8px rgba(0,0,0,0.5) , 3px 5px 15px rgba(68,68,68,0.76);
}
#carouselSite .carousel-item {
    height: 60vh;
    min-height: 200px;
    background: no-repeat top center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    animation: move 40s ease;
    -ms-animation: move 40s ease;
    -webkit-animation: move 40s ease;
}

/* Testimonial Carousel */
#carouselTestimonial .carousel-item {
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: $bg-color;
    border-radius: 1em;
}
#carouselTestimonial .carousel-caption {
    font-family: $primary-font;
    right: 20px;
    left: 20px;
}

/* Titles */
h1,h2,h3,h4,h5,h6,h7 {
    font-family: $primary-font;
}

.wide-title {
    text-align: center;
    font-family: $primary-font;
    padding-top: 1em;
    padding-bottom: 1em;
    line-height: 1.5em;
}

h1.wide-title:before {
    top: 1em;
}

h5.wide-title:before {
    top: 1em;
}

.wide-title:before {
    content: "";
    display: block;
    border-top: solid 1px $border-color;
    width: 100%;
    height: 1px;
    position: relative;
    z-index: 1;
}

.wide-title span {
    background: $white;
    padding: 0 .5em;
    position: relative;
    z-index: 5;
}
.sub-title {
    text-align: center;
    font-family: $primary-font;
}

.btn {
    font-family: $primary-font;
}

.btn-paw {
    color: $white !important;
    background: $primary url("../images/paw_btn.png") no-repeat left center;
    border-color: $primary;

}
.btn-paw:hover {
    color: $dark;
}


  .paws {
    background: $primary url("../images/paw_pattern.png") repeat center center;
  }
  .red {
    color: $primary;
  }

  .nav-link {
      color: $dark !important;
      font-family: $primary-font;
  }

  .nav-link:hover {
    color: $darker !important;
  }
  .nav-link {
    border-bottom: 2px solid $primary;
  }
  .nav-link.active {
    color: $darker !important;
    border-bottom: 2px solid $white;
  }
  .pointer {
      cursor: pointer;
      color: $dark;
  }

  .pointer:hover {
    color: $darker;
  }

  .footer {
    background-color:$darker;
    padding: 1em;
    color: $white;
  }
.nav-container {
    padding-bottom: 156px;
}

@media (max-width: 576px) { 
    .logo {
        width: 154px;
    }
    #carouselTestimonial .carousel-item {
        height: 600px;
    }
    .header-spacer {
        height: 25px;
    }
    .page-nav {
        top: 50px;
    }
    .page-banner-area {
        top: -55px;
    }
    #carouselSite {
        top: -55px;
    }
    #carouselSite .carousel-caption > h1 {
        font-size: 1.5em !important;
    }

}

@media (min-width: 576px) and (max-width: 768px) { 
    .logo {
        width: 154px;
    }
    .header-spacer {
        height: 50px;
    }

    #carouselTestimonial .carousel-item {
        height: 400px;
    }
    .page-nav {
        top: 70px;
    }
    .page-banner-area {
        top: -55px;
    }
    #carouselSite {
        top: -55px;
    }
    #carouselSite .carousel-caption > h1 {
        font-size: 2em !important;
    }
}

/* Large screens */
@media (min-width: 768px) {
    .logo {
        width: 221px;
    }
    .header-spacer {
        height: 75px;
    }
    #carouselTestimonial .carousel-item {
        height: 300px;
    }
    .page-nav {
        top: 100px;
    }
    #carouselSite .carousel-caption {
        bottom: auto;
        top: 50%;
    }
}

.anchor-item {
    padding-top: 50px;
    margin-top: -50px;
}

/* Custom Backgrounds */
.bg-orange {
    background-color:$primary !important;
}
.bg-grey {
    background-color: $border-color;
}

/* Menu */
@media all and (max-width: 992px) {
    .nav-link {
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        text-align: center;
    }
    .nav-link:hover {
        border-bottom: 2px solid #fff;
    }
}