// Imports
@import "base";
@import "type";
@import "layout";

/* header */

/* menu */
.header ul {
    overflow: hidden;
}

.header .menu {
    clear: both;
    max-height: 0;
    transition: max-height .2s ease-out;
}

/* menu icon */

.header .menu-icon {
    cursor: pointer;
    display: inline-block;
    padding: 18px 18px;
    position: relative;
    user-select: none;
}

.header .menu-icon .navicon {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
    background: #333;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}

.header .menu-icon .navicon:before {
    top: 5px;
}

.header .menu-icon .navicon:after {
    top: -5px;
}

/* menu btn */

.header .menu-btn {
    display: none;
}

.header .menu-btn:checked~.menu {
    max-height: 240px;
}

.header .menu-btn:checked~.menu-icon .navicon {
    background: transparent;
}

.header .menu-btn:checked~.menu-icon .navicon:before {
    transform: rotate(-45deg);
}

.header .menu-btn:checked~.menu-icon .navicon:after {
    transform: rotate(45deg);
}

.header .menu-btn:checked~.menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked~.menu-icon:not(.steps) .navicon:after {
    top: 0;
}

/* 48em = 768px */

@media (min-width: 992px) {
    .header li {
        float: left;
    }

    .header .menu {
        clear: none;
        float: right;
        max-height: none;
    }

    .header .menu-icon {
        display: none;
    }
}